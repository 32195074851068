/* istanbul ignore file */
/* eslint-disable */

let loaded = false

export const loadVWO = async () => {
  if (loaded) return
  // Source: https://app.vwo.com/#/settings/code
  window._vwo_code=window._vwo_code || (function() {
    let account_id = 305960,
      version = 1.5,
      settings_tolerance = 2000,
      library_tolerance = 2500,
      use_existing_jquery = false,
      is_spa = 1,
      hide_element = 'body',
      hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
      /* DO NOT EDIT BELOW THIS LINE */
      f = false, w = window, d = document, vwoCodeEl = d.querySelector('#vwoCode'), code = {
        use_existing_jquery: function () {
          return use_existing_jquery
        }, library_tolerance: function () {
          return library_tolerance
        }, hide_element_style: function () {
          return '{' + hide_element_style + '}'
        }, finish: function () {
          if (!f) {
            f = true
            const e = d.getElementById('_vis_opt_path_hides')
            if (e) e.parentNode.removeChild(e)
          }
        }, finished: function () {
          return f
        }, load: function (e) {
          const t = d.createElement('script')
          t.fetchPriority = 'high'
          t.src = e
          t.type = 'text/javascript'
          t.onerror = function () {
            _vwo_code.finish()
          }
          d.getElementsByTagName('head')[0].appendChild(t)
        }, getVersion: function () {
          return version
        }, getMatchedCookies: function (e) {
          let t = []
          if (document.cookie) {
            t = document.cookie.match(e) || []
          }
          return t
        }, getCombinationCookie: function () {
          let e = code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi)
          e = e.map(function (e) {
            try {
              const t = decodeURIComponent(e)
              if (!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)) {
                return ''
              }
              return t
            } catch (e) {
              return ''
            }
          })
          const i = []
          e.forEach(function (e) {
            const t = e.match(/([\d,]+)/g)
            t && i.push(t.join('-'))
          })
          return i.join('|')
        }, init: function () {
          if (d.URL.indexOf('__vwo_disable__') > -1) return
          w.settings_timer = setTimeout(function () {
            _vwo_code.finish()
          }, settings_tolerance)
          const e = d.currentScript, t = d.createElement('style'),
            i = e && !e.async ? hide_element ? hide_element + '{' + hide_element_style + '}' : '' : code.lA = 1,
            n = d.getElementsByTagName('head')[0]
          t.setAttribute('id', '_vis_opt_path_hides')
          vwoCodeEl && t.setAttribute('nonce', vwoCodeEl.nonce)
          t.setAttribute('type', 'text/css')
          if (t.styleSheet) {
            t.styleSheet.cssText = i
          } else {
            t.appendChild(d.createTextNode(i))
          }
          n.appendChild(t)
          const o = this.getCombinationCookie()
          this.load('https://dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(d.URL) + '&f=' + +is_spa + '&vn=' + version + (o ? '&c=' + o : ''))
          return settings_timer
        }
      }
    w._vwo_settings_timer = code.init();return code;}());
  loaded = true
}
